<template>
  <v-main app>
    <v-container class="pt-6">
      <!-- If using vue-router -->
      <v-expand-transition mode="out-in">
        <router-view />
      </v-expand-transition>
    </v-container>
    <div class="flex-grow-1" />
    <app-footer />
  </v-main>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      AppFooter: () => import('./Footer'),
    },
    computed: {
      ...mapGetters(['getThemeMode']),
    },
  }
</script>

<style lang="scss">
.v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
</style>
